import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserPlus,
  faCirclePlay,
  faClipboard,
  faGear,
  faRobot,
  faDatabase,
  faCheck,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  UsersIcon,
  TableCellsIcon,
  CurrencyDollarIcon,
  CpuChipIcon,
  BriefcaseIcon,
} from "@heroicons/react/24/outline";
import Banner from "./Banner.js";
import Select from "react-select"; // Importing react-select for multi-select dropdown
import CreatableSelect from "react-select/creatable";

import { sourceOptions } from "./Sources.js";

import BuyData from "./BuyData.js";
import MyAgents from "./MyAgents.js";
import SubscribeManageAccount from "./SubscribeManageAccount.js";

const ActionCard = ({
  Icon,
  title,
  description,
  onClick,
  iconColor,
  active,
}) => (
  <button
    onClick={onClick}
    className={`border py-2 md:py-4 px-5 transition duration-200 ease-in-out ${
      active ? "shadow-lg border-blue-500 border-2" : ""
    } hover:border-blue-400 hover:shadow-md`}
  >
    <div className="flex">
      <div className="items-start">
        <Icon className={`h-8 w-8 md:h-12 md:w-12 ${iconColor}`} />
      </div>
      <div className="mx-auto justify-center items-center text-center">
        <h2 className="font-bold text-md md:text-lg">{title}</h2>
        <p className="text-sm md:text-md text-gray-600">{description}</p>
      </div>
    </div>
  </button>
);

const DisabledCard = ({ Icon, title, description, iconColor, isFetching }) => (
  <div className="border py-2 md:py-4 px-5 bg-gray-200">
    <div className="flex">
      <div className="items-start">
        <Icon className={`h-8 w-8 md:h-12 md:w-12 ${iconColor}`} />
      </div>
      <div className="mx-auto justify-center items-center text-center">
        <h2 className="font-bold text-md md:text-lg">{title}</h2>
        <p className="text-sm md:text-md text-gray-600">
          {isFetching ? "Loading..." : description}
        </p>
      </div>
    </div>
  </div>
);

const Profile = () => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();
  const [agents, setAgents] = useState([]);
  const [activeComponent, setActiveComponent] = useState("MyAgents");
  const [accessToken, setAccessToken] = useState("");
  const [userRuns, setUserRuns] = useState(0);

  const domain = "alphainquire.us.auth0.com";

  const getUserMetadata = async () => {
    try {
      const token = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });

      setAccessToken(token); // Store the access token in state

      const userDetailsByIdUrl = `https://${domain}/api/v2/users/${encodeURIComponent(
        user.sub
      )}`;

      const metadataResponse = await fetch(userDetailsByIdUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { user_metadata, email } = await metadataResponse.json();
      const userAgents = user_metadata.agents || [];
      setAgents(userAgents);

      // Add the sources of all agents to sourceOptions
      userAgents.forEach((agent) => {
        agent.sources.forEach((source) => {
          if (
            sourceOptions.findIndex(
              (sourceOption) => sourceOption.value === source
            ) === -1
          ) {
            // This is a new source, add it to the sourceOptions list
            sourceOptions.push({ value: source, label: source });
          }
        });
      });

    // Fetch runs
    const runsResponse = await fetch(`/api/getRuns?username=${encodeURIComponent(email)}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!runsResponse.ok) {
      throw new Error('Failed to fetch runs');
    }
    const runsData = await runsResponse.json();
    if (runsData && runsData.length > 0) {
        setUserRuns(runsData[0].runs);
    }
  } catch (e) {
    console.error(e);
  }
};

  useEffect(() => {
  if (user?.sub) {
    getUserMetadata();
  }
}, [getAccessTokenSilently, user?.sub]);

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(accessToken);
      alert("API key copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy API key: ", err);
    }
  };

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <div className="pb-12 flex flex-col">
      <Banner runs={userRuns} />
      <div className="w-5/6 md:max-w-5xl mx-auto mt-2">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
          <ActionCard
            Icon={CpuChipIcon}
            title="My Research Agents"
            description="Manage and create research agents"
            onClick={() => setActiveComponent("MyAgents")}
            iconColor="text-blue-500"
            active={activeComponent === "MyAgents"}
          />

          <ActionCard
            Icon={BriefcaseIcon}
            title="Subscribe & Manage My Account"
            description="Get AlphaInquire e-mail updates daily"
            onClick={() => setActiveComponent("SubscribeManageAccount")}
            iconColor="text-blue-500"
            active={activeComponent === "SubscribeManageAccount"}
          />

        </div>
      </div>

      <hr class="my-4 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100" />

      {(() => {
        switch (activeComponent) {
          case "BuyData":
            return <BuyData handleCopyToClipboard={handleCopyToClipboard} />;

          case "MyAgents":
            return (
              <MyAgents
                agents={agents}
                setAgents={setAgents}
                user={user}
                accessToken={accessToken}
                getAccessTokenSilently={getAccessTokenSilently}
                sourceOptions={sourceOptions}
                userRuns={userRuns}
                getUserMetadata={getUserMetadata}
              />
            );
          case "SubscribeManageAccount":
            return <SubscribeManageAccount user={user} />;

          default:
            return null;
        }
      })()}
    </div>
  );
};

export default Profile;
