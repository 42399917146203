// Chat.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { sourceOptions } from './Sources.js';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';


const Chat = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const username = user.name;
  const domain = "alphainquire.us.auth0.com";
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [selectedSources, setSelectedSources] = useState([
    { value: 'search_google', label: 'Google' },
    { value: 'search_recent_news', label: 'Popular News' }
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [oldChats, setOldChats] = useState([]);
  const [isLoadingChats, setIsLoadingChats] = useState(true);

  useEffect(() => {
    const getAccessToken = async () => {
      const token = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });
      setAccessToken(token);
    };
    getAccessToken();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (accessToken) {
      fetchOldChats();
    }
  }, [accessToken]);

  const handleNewMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handleSendMessage = () => {
    if (!accessToken) {
      console.error("Access token not available");
      return;
    }

    setIsLoading(true);

    const createChatUrl = "https://cpdough--chat-api-create-chat.modal.run";
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${accessToken}`,
    };
    const new_conversation = {
      message: newMessage,
      sources: selectedSources.map(option => option.value),
    };

    axios.post(createChatUrl, new_conversation, { headers })
      .then(response => {
        const chat_uuid = response.data.chat_uuid;
        navigate(`/chat/${chat_uuid}`);
      })
      .catch(error => {
        console.error("Create Chat Failed:", error);
        setIsLoading(false);
      });
  };

  const handleSourcesChange = (selectedOptions) => {
    setSelectedSources(selectedOptions);
  };

  const fetchOldChats = async () => {
    setIsLoadingChats(true);
    const url = "https://cpdough--chat-api-get-chat-conversations.modal.run";
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${accessToken}`,
    };
  
    try {
      const response = await axios.get(url, { headers });
      setOldChats(response.data);
      setIsLoadingChats(false);
    } catch (error) {
      console.error("Fetch Old Chats Failed:", error);
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-base font-semibold leading-7 text-gray-900">Start a New Conversation</h2>
      <div className="max-w-md mx-auto">
        <label htmlFor="sources" className="block text-sm font-medium text-gray-700">Select Data Sources</label>
        <Select
          isMulti
          name="sources"
          options={sourceOptions}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={handleSourcesChange}
          value={selectedSources}
        />
      </div>
      <div className="mt-4 flex">
        <input type="text" value={newMessage} onChange={handleNewMessageChange} className="border rounded p-2 w-full" onKeyPress={event => {if (event.key === 'Enter') { event.preventDefault(); handleSendMessage(); }}} 
          placeholder="Ask AI to research something..."
        />
        <button onClick={handleSendMessage} disabled={isLoading} className={`bg-blue-500 text-white rounded p-2 ml-2 flex items-center ${isLoading ? 'opacity-50' : ''}`}>
          {isLoading ? 'Sending...' : <><FontAwesomeIcon icon={faPaperPlane} className="mr-2" />  Send</>}
        </button>
      </div>
      <div className="mt-10">
      <h2 className="text-base font-semibold leading-7 text-gray-900">My Chat Conversations</h2>
      {isLoadingChats ? (
        <p>Loading prior conversations...</p>
      ) : oldChats.length === 0 ? (
        <p>No Prior Conversations</p>
      ) : (
        oldChats.map((chat, index) => (
          <Link to={`/chat/${chat.chat_uuid}`} key={index} className="block p-4 border rounded mt-2 bg-white shadow">
            <div>
              <p className="text-gray-700">{chat.message.substring(0, 100)}...</p>
              <p className="text-sm text-gray-500">{new Date(chat.timestamp).toLocaleString()}</p>
            </div>
          </Link>
        ))
      )}
    </div>
    </div>
  );
};

export default Chat;