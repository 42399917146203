import LogoutButton from "./LogoutButton";
import { Fragment, useState } from "react";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import { HashLink as Link } from "react-router-hash-link";
import {
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  EnvelopeIcon,
  DocumentChartBarIcon,
  XMarkIcon,
  CpuChipIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const products = [
  {
    name: "Research Agents",
    description: "AI Agents that do research for you",
    href: "/researchagent",
    icon: CursorArrowRaysIcon,
  },
  {
    name: "Subprocessor Data",
    description: "Data on GDPR Subprocessors",
    href: "/data",
    icon: ChartPieIcon,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header({ isAuthenticated, loginWithRedirect }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="sticky z-20">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            {!mobileMenuOpen && (
              <>
                <span className="sr-only">Alpha Inquire</span>
                <img className="h-14 w-auto" src="/ai_logo.png" alt="Logo" />
              </>
            )}
          </a>
        </div>
        <div className="flex lg:hidden">
          {!mobileMenuOpen && (
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          )}
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          <a
            href="/request"
            className="text-sm font-semibold leading-6 text-gray-900 flex items-center"
          >
            <EnvelopeIcon className="h-5 w-5 mr-2" aria-hidden="true" />
            Contact Us
          </a>
          <a
            href="/profile"
            className="text-sm font-semibold leading-6 text-gray-900 flex items-center"
          >
            <CpuChipIcon className="h-5 w-5 mr-2" aria-hidden="true" />
            My Agents
          </a>
        </Popover.Group>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          {isAuthenticated ? (
            <LogoutButton />
          ) : (
            <Link
              smooth
              to="/profile"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Login
            </Link>
          )}
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Alpha Inquire</span>
              <img className="h-8 w-auto" src="/ai_logo.png" alt="" />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Link
                  smooth
                  to="/"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Home
                </Link>
                <Link
                  smooth
                  to="/request"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 flex items-center"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <EnvelopeIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                  Contact Us
                </Link>
                <a
                  href="/profile"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 flex items-center"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <CpuChipIcon
                    className="h-5 w-5 mr-2"
                    aria-hidden="true"
                  />
                  My Agents
                </a>
              </div>
              <div className="py-6">
                {isAuthenticated ? (
                  <LogoutButton />
                ) : (
                  <Link
                    smooth
                    to="/profile"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Login
                  </Link>
                )}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
