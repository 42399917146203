import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons"; // Import the spinner icon
import {
  faCheck,
  faPencilAlt,
  faArrowUp,
  faArrowDown,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import {
  faGear,
  faCirclePlay,
  faUserPlus,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import Select from "react-select";
import { Link } from "react-router-dom";

import { CuratedAgents } from "./CuratedAgents.js";

const domain = "alphainquire.us.auth0.com";

const dayOptions = [
  { value: "Monday", label: "Monday" },
  { value: "Tuesday", label: "Tuesday" },
  { value: "Wednesday", label: "Wednesday" },
  { value: "Thursday", label: "Thursday" },
  { value: "Friday", label: "Friday" },
  { value: "Saturday", label: "Saturday" },
  { value: "Sunday", label: "Sunday" },
];

const MyAgents = ({
  agents,
  setAgents,
  user,
  accessToken,
  getAccessTokenSilently,
  sourceOptions,
  userRuns,
  getUserMetadata,
}) => {
  const [currentAgentIndex, setCurrentAgentIndex] = useState(null);
  const [agentName, setAgentName] = useState("");
  const [newInterest, setNewInterest] = useState("");
  const [runningAgentName, setRunningAgentName] = useState(null);
  const [isEditingAgentName, setIsEditingAgentName] = useState(false);
  const [editState, setEditState] = useState({ isEditing: false, name: "", index: null });
  const [aiAgentTopic, setAiAgentTopic] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const toggleAgentDetail = (index) => {
    setCurrentAgentIndex(currentAgentIndex !== null ? null : index);
    // Reset edit state when toggling between agents
    setEditState({ isEditing: false, name: "", index: null });
  };

  const enterEditMode = (index) => {
    setEditState({ isEditing: true, name: agents[index].name, index });
  };

  const runAgent = async (agentName) => {
    setRunningAgentName(agentName);
    const url = "https://cpdough--alphainquire-api-run-user.modal.run";
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };

    const body = JSON.stringify({
      agent_name: agentName,
    });

    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: body,
    });

    if (response.ok) {
      const data = await response.text(); // Use .text() instead of .json() for plain string response
      console.log(data);
      alert(`Success: ${data}`); // Directly use the data in the alert
    } else {
      console.error(`Error: ${response.status} - ${response.statusText}`);
      alert(`Error: ${response.status} - ${response.statusText}`);
    }
    setRunningAgentName(null);
  };

  const createAiAgent = async () => {
    if (!aiAgentTopic) {
      alert("Please enter a topic for the AI agent.");
      return;
    }
    setIsLoading(true);

    try {
      const token = await getAccessTokenSilently();
      const response = await fetch("/api/newAgent", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ prompt: aiAgentTopic }),
      });

      if (!response.ok) {
        throw new Error("Failed to create AI agent");
      }

      const data = await response.json();
      console.log("AI Agent created:", data);
      alert("AI Agent created successfully");
      setAiAgentTopic(""); // Reset the input field
      await getUserMetadata(); // Refresh the agents list
    } catch (error) {
      console.error("Error creating AI agent:", error);
      alert("Error creating AI agent");
    }
    setIsLoading(false);
  };

  const handleAddAndSaveAgent = async (updatedAgents) => {
    try {
      const token = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "update:current_user_metadata",
      });

      const url = `https://${domain}/api/v2/users/${encodeURIComponent(
        user.sub
      )}`;

      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_metadata: { agents: updatedAgents },
        }),
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        alert(
          `An error occurred while updating the agents: ${errorResponse.message}`
        );
      }
    } catch (error) {
      console.error("An error occurred while updating the agents:", error);
    }
  };

  const handleAddNewAgent = () => {
    const newAgent = {
      name: "New Agent",
      interests: [],
      sources: [],
      days: dayOptions.map((option) => option.value), // All days selected by default
    };
    setAgents([...agents, newAgent]);
    setCurrentAgentIndex(agents.length);
  };

  const handleDeleteAgent = async (agentIndex) => {
    const updatedAgents = [...agents];
    updatedAgents.splice(agentIndex, 1);
    setAgents(updatedAgents);
    handleAddAndSaveAgent(updatedAgents);
  };

  const handleDeleteInterest = async (agentIndex, interestIndex) => {
    const updatedAgents = [...agents];
    updatedAgents[agentIndex].interests.splice(interestIndex, 1);
    setAgents(updatedAgents);
    handleAddAndSaveAgent(updatedAgents);
  };

  const handleAddInterest = (agentIndex) => {
    const updatedAgents = [...agents];
    updatedAgents[agentIndex].interests.push(newInterest);
    setAgents(updatedAgents);
    setNewInterest("");
    handleAddAndSaveAgent(updatedAgents);
  };

  const moveInterestUp = (agentIndex, interestIndex) => {
    const updatedAgents = [...agents];
    if (interestIndex > 0) {
      const temp = updatedAgents[agentIndex].interests[interestIndex];
      updatedAgents[agentIndex].interests[interestIndex] =
        updatedAgents[agentIndex].interests[interestIndex - 1];
      updatedAgents[agentIndex].interests[interestIndex - 1] = temp;
      setAgents(updatedAgents);
      handleAddAndSaveAgent(updatedAgents);
    }
  };

  const moveInterestDown = (agentIndex, interestIndex) => {
    const updatedAgents = [...agents];
    if (interestIndex < updatedAgents[agentIndex].interests.length - 1) {
      const temp = updatedAgents[agentIndex].interests[interestIndex];
      updatedAgents[agentIndex].interests[interestIndex] =
        updatedAgents[agentIndex].interests[interestIndex + 1];
      updatedAgents[agentIndex].interests[interestIndex + 1] = temp;
      setAgents(updatedAgents);
      handleAddAndSaveAgent(updatedAgents);
    }
  };

  // Handler for source selection
  const handleSourceChange = (selectedOption, agentIndex) => {
    const updatedAgents = [...agents];
    updatedAgents[agentIndex].sources = selectedOption.map(
      (option) => option.value
    );
    setAgents(updatedAgents);
    handleAddAndSaveAgent(updatedAgents);

    // Add new options to the sourceOptions list
    selectedOption.forEach((option) => {
      if (
        sourceOptions.findIndex(
          (sourceOption) => sourceOption.value === option.value
        ) === -1
      ) {
        // This is a new option, add it to the sourceOptions list
        sourceOptions.push(option);
      }
    });
  };

  const handleDayChange = (selectedOption, agentIndex) => {
    const updatedAgents = [...agents];
    updatedAgents[agentIndex].days = selectedOption.map(
      (option) => option.value
    );
    setAgents(updatedAgents);
    handleAddAndSaveAgent(updatedAgents);
  };

  const submitNameChange = () => {
    if (editState.index !== null) {
      const updatedAgents = [...agents];
      updatedAgents[editState.index].name = editState.name;
      setAgents(updatedAgents);
      handleAddAndSaveAgent(updatedAgents);
      // Exit edit mode
      setEditState({ isEditing: false, name: "", index: null });
    }
  };

  const loadCuratedAgent = async (index) => {
    const CuratedAgent = CuratedAgents[index];
    setAgentName(CuratedAgent.name);
    const updatedAgents = [...agents, CuratedAgent];
    setAgents(updatedAgents);
    await handleAddAndSaveAgent(updatedAgents); // Save the new agent in the backend
  };

  return (
    <div className="xl:w-2/3 w-full mx-auto justify-center items-center mt-3">
      {currentAgentIndex === null && (
        <>
          <h2 className="text-xl text-center font-semibold leading-3 mb-4 text-gray-900">
            My Research Agents
          </h2>
          <div className="flex items-center justify-center md:flex-row mb-2">
            <input
              type="text"
              placeholder="Topic are you interested in"
              value={aiAgentTopic}
              onChange={(e) => setAiAgentTopic(e.target.value)}
              className="border rounded-md border-gray-300 py-2 px-3 mb-2 ml-2 w-full"
            />
            <button
              type="button"
              onClick={createAiAgent}
              className="flex-shrink-0 cursor-pointer border rounded-md border-gray-300 py-2 px-3 mb-2 hover:bg-gray-200 mx-2"
              disabled={isLoading} // Disable the button while loading
            >
              <div className="text-center text-gray-600 text-sm font-semibold justify-center items-center">
                {isLoading ? (
                  <>
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className="text-blue-500 fa-spin"
                    />{" "}
                    Creating...
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon
                      icon={faUserPlus}
                      className="text-blue-500"
                    />{" "}
                    Create Agent
                  </>
                )}
              </div>
            </button>
          </div>
          {agents.map((agent, index) => (
            <div
              key={index}
              className="flex justify-between items-center bg-gray-50 shadow rounded p-4 mb-3 mx-2"
            >
              <div className="flex-grow min-w-0">
                <h3 className="text-lg font-semibold text-gray-900 text-left">
                  {agent.name}
                </h3>
                <p className="text-sm text-gray-600 hidden lg:block">
                  {agent.interests.join(", ")}
                </p>
              </div>
              <div className="flex-shrink-0">
                <button
                  type="button"
                  className="bg-blue-500 text-white text-sm p-2 rounded mx-1"
                  onClick={() => toggleAgentDetail(index)}
                >
                  <FontAwesomeIcon icon={faGear} /> Settings
                </button>
                <button
                  type="button"
                  onClick={() => runAgent(agent.name)}
                  className={`text-sm p-2 rounded mx-1 ${
                    userRuns > 0 || agent.name === runningAgentName
                      ? "bg-gray-500 text-white cursor-not-allowed"
                      : "bg-blue-500 text-white"
                  }`}
                  disabled={userRuns > 0 || agent.name === runningAgentName}
                >
                  {agent.name === runningAgentName ? (
                    <>
                      <FontAwesomeIcon icon={faCirclePlay} /> Loading...
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faCirclePlay} /> Run Now
                    </>
                  )}
                </button>
                <button
                  type="button"
                  onClick={() => handleDeleteAgent(index)}
                  className="bg-red-500 text-sm text-white p-2 rounded mx-1"
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </button>
              </div>
            </div>
          ))}
        </>
      )}

      {currentAgentIndex !== null && (
        <>
          <div className="flex flex-col mx-3">
            <div className="flex flex-row flex-wrap justify-between">
              <div class="text-sm">
                <button
                  type="button"
                  onClick={() => setCurrentAgentIndex(null)}
                  className="bg-blue-500 text-white py-1 px-2 rounded"
                >
                  <FontAwesomeIcon icon={faArrowLeft} /> My Agents
                </button>
              </div>
            </div>
            <div className="flex flex-row flex-wrap justify-between">
              <div class="text-center flex-grow">
              <h2 className="text-xl font-semibold leading-7 text-gray-900">
            <span className="pl-2">
              {editState.isEditing && editState.index === currentAgentIndex ? (
                <>
                  <input
                    type="text"
                    value={editState.name}
                    onChange={(e) => setEditState({ ...editState, name: e.target.value })}
                    className="border border-gray-300 p-2"
                  />
                  <button
                    type="button"
                    onClick={submitNameChange}
                    className="ml-2 bg-blue-500 text-sm text-white px-2 py-1 rounded"
                  >
                    <FontAwesomeIcon icon={faCheck} /> Save
                  </button>
                </>
              ) : (
                <>
                  {agents[currentAgentIndex].name}
                  <button
                    type="button"
                    onClick={() => enterEditMode(currentAgentIndex)}
                    className="ml-2 bg-blue-500 text-xs text-white font-normal px-1 py-1 rounded"
                  >
                    <FontAwesomeIcon icon={faPencilAlt} /> Rename
                  </button>
                </>
              )}
            </span>
          </h2>
              </div>
            </div>

            {/* Multi-select dropdown for sources */}
            <div className="py-10">
              <h3 className="text-base font-semibold text-gray-900">
                Which sources should this agent use?
              </h3>
              <p className="text-sm leading-6 text-gray-600">
                Select from available sources or enter a valid URL or RSS Feed
              </p>
              <CreatableSelect
                isMulti
                name="sources"
                options={sourceOptions}
                className="basic-multi-select"
                placeholder="Select or enter a source..."
                classNamePrefix="select"
                onChange={(selectedOption) =>
                  handleSourceChange(selectedOption, currentAgentIndex)
                }
                value={agents[currentAgentIndex].sources.map((source) =>
                  sourceOptions.find((option) => option.value === source)
                )}
                isSearchable={true}
              />
            </div>

            <h3 className="text-base font-semibold text-gray-900">
              What interests should this agent research?
            </h3>
            <p className="text-sm leading-6 text-gray-600">
              Interests are the topics you want AI to research and summarize for
              you. They should be specific, like "large language models".
            </p>
            <div className="flex flex-row justify-between mt-2">
              <input
                type="text"
                value={newInterest}
                onChange={(e) => setNewInterest(e.target.value)}
                placeholder="Enter an interest and click Add"
                className="border border-gray-300 p-2 w-full"
              />
              <button
                type="button"
                onClick={() => handleAddInterest(currentAgentIndex)}
                className="flex-shrink-0 ml-2 bg-blue-500 text-white p-2 rounded"
              >
                (+) Add
              </button>
            </div>
            <div className="flex flex-col space-y-2">
              {agents[currentAgentIndex].interests.map(
                (interest, interestIndex) => (
                  <div
                    key={interestIndex}
                    className="flex justify-between items-center bg-white shadow rounded mt-2 p-2"
                  >
                    <div className="flex-grow min-w-0 text-sm lg:text-base font-semibold">
                      {interest}
                    </div>
                    <div className="flex-shrink-0">
                      <button
                        type="button"
                        onClick={() =>
                          moveInterestUp(currentAgentIndex, interestIndex)
                        }
                        className="bg-blue-500 text-white p-1 rounded mr-1"
                      >
                        <FontAwesomeIcon icon={faArrowUp} />
                      </button>
                      <button
                        type="button"
                        onClick={() =>
                          moveInterestDown(currentAgentIndex, interestIndex)
                        }
                        className="bg-blue-500 text-white p-1 rounded mr-1"
                      >
                        <FontAwesomeIcon icon={faArrowDown} />
                      </button>
                      <button
                        type="button"
                        onClick={() =>
                          handleDeleteInterest(currentAgentIndex, interestIndex)
                        }
                        className="bg-red-500 text-white p-1 rounded"
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </button>
                    </div>
                  </div>
                )
              )}
            </div>
            <h3 className="text-base font-semibold text-gray-900 mt-8">
              Which days should this agent run?
            </h3>
            <p className="text-sm leading-6 text-gray-600">
              Select the days of the week that the agent should run and send you
              the results.
            </p>
            <CreatableSelect
              isMulti
              name="days"
              options={dayOptions}
              className="basic-multi-select"
              placeholder="Select days..."
              classNamePrefix="select"
              onChange={(selectedOption) =>
                handleDayChange(selectedOption, currentAgentIndex)
              }
              value={
                agents[currentAgentIndex]?.days?.length > 0
                  ? agents[currentAgentIndex].days.map((day) =>
                      dayOptions.find((option) => option.value === day)
                    )
                  : dayOptions // Default to all options if days are not set
              }
            />
          </div>
        </>
      )}
    </div>
  );
};

export default MyAgents;
