export const Companies = [
    "Apple Inc.",
    "Microsoft Corporation",
    "NVIDIA Corporation",
    "Taiwan Semiconductor Manufacturing Company Limited",
    "Broadcom Inc.",
    "Oracle Corporation",
    "Adobe Inc.",
    "ASML Holding N.V.",
    "Cisco Systems, Inc.",
    "Salesforce, Inc.",
    "Accenture plc",
    "Advanced Micro Devices, Inc.",
    "SAP SE",
    "Intel Corporation",
    "Intuit Inc.",
    "Texas Instruments Incorporated",
    "International Business Machines Corporation",
    "QUALCOMM Incorporated",
    "ServiceNow, Inc.",
    "Applied Materials, Inc.",
    "Sony Group Corporation",
    "Uber Technologies, Inc.",
    "Analog Devices, Inc.",
    "Lam Research Corporation",
    "Micron Technology, Inc.",
    "Shopify Inc.",
    "Palo Alto Networks, Inc.",
    "Synopsys, Inc.",
    "Fiserv, Inc.",
    "Infosys Limited",
    "KLA Corporation",
    "Cadence Design Systems, Inc.",
    "Arista Networks, Inc.",
    "VMware, Inc.",
    "Workday, Inc.",
    "Arm Holdings Limited",
    "Amphenol Corporation",
    "Dell Technologies Inc.",
    "Motorola Solutions, Inc.",
    "Snowflake Inc.",
    "NXP Semiconductors N.V.",
    "Atlassian Corporation Plc",
    "CrowdStrike Holdings, Inc.",
    "Marvell Technology, Inc.",
    "Autodesk, Inc.",
    "Microchip Technology Incorporated",
    "Palantir Technologies Inc.",
    "TE Connectivity Ltd.",
    "The Trade Desk, Inc.",
    "Fortinet, Inc.",
    "ST Microelectronics",
    "Cognizant Technology Solutions Corporation",
    "Fidelity National Information Services, Inc.",
    "Gartner, Inc.",
    "Block, Inc.",
    "ON Semiconductor Corporation",
    "GLOBALFOUNDRIES Inc.",
    "CDW Corporation",
    "HP Inc.",
    "Datadog, Inc.",
    "ANSYS, Inc.",
    "Splunk Inc.",
    "Zscaler, Inc.",
    "MongoDB, Inc.",
    "Wipro Limited",
    "Corning Incorporated",
    "Monolithic Power Systems, Inc.",
    "CGI Inc.",
    "Fortive Corporation",
    "Fair Isaac Corporation",
    "Telefónica, S.A.",
    "Keysight Technologies, Inc.",
    "Garmin Ltd.",
    "Cloudflare, Inc.",
    "HubSpot, Inc."
    ];