import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { Auth0Provider, withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react'; // Import the Auth0 hook
import HomePage from './HomePage';
import Profile from './Profile';
import Header from "./Header";
import Chat from "./Chat";
import ChatPage from "./ChatPage";
import PrivacyPolicy from "./PrivacyPolicy";
import Footer from "./Footer";
import Docs from "./Docs";
import Data from "./Data";
import ResearchAgent from "./ResearchAgent";
import logo from './logo.svg';
import './App.css';
import ReactGA from "react-ga4";
import RequestForm from './RequestData';

const ProtectedRoute = ({ component, ...args }) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

const FooterWrapper = () => {
  const location = useLocation();
  const path = location.pathname;

  // Don't render Footer on these paths
  if (path.startsWith('/profile')) {
    return null;
  }

  return <Footer />;
};

export default function App() {
  useEffect(() => {
    ReactGA.initialize("G-98VJ9FT5NF");
  }, []);

  return (
    <BrowserRouter>
      <Auth0ProviderWithRedirectCallback
      domain="alphainquire.us.auth0.com"
      clientId="tmjRLUAtbk9XyyYCKq1YnRGycLWsTYbG"
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: "https://alphainquire.us.auth0.com/api/v2/",
          scope: "openid profile email read:current_user update:current_user_metadata"
        }}
      >
        <HeaderWrapper />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/data" element={<Data />} />
          <Route path="/researchagent" element={<ResearchAgent />} />
          <Route path="/request" element={<RequestForm />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/profile"
            element={<ProtectedRoute component={Profile} />}
          />
          <Route
            path="/docs"
            element={<ProtectedRoute component={Docs} />}
          />
        </Routes>
        <FooterWrapper />
      </Auth0ProviderWithRedirectCallback>
    </BrowserRouter>
  );
}

const HeaderWrapper = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  return <Header isAuthenticated={isAuthenticated} loginWithRedirect={loginWithRedirect} />;
};
