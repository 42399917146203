import React from 'react';
import { Helmet } from 'react-helmet';
import { CloudArrowUpIcon, LockClosedIcon, ServerIcon, CheckIcon } from '@heroicons/react/20/solid';
import { CodeBlock, CopyBlock } from 'react-code-blocks';
import { Tab } from '@headlessui/react';



const includedFeatures = [
  'Daily scheduled research summary',
  'Alpha Inquire e-mails delivered to your inbox',
  'Manage your agent: set data sources and interests',
  'API Access for power users',
]

const features = [
  {
    name: 'Subprocessor data tells you what software companies are using to process their customer information.',
    description:
      'How many companies are using Stripe? How many are using OpenAI? How many are using both?',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'GDPR regulations mean that most tech companies are required to disclose their subprocessors.',
    description: 'Here is an example: https://zapier.com/legal/subprocessors',
    icon: LockClosedIcon,
  },
  {
    name: 'Alpha Inquire uses AI agents to scrape this data from the web and keep it updated.',
    description: 'This chart shows the top 5 subprocessors by market share in our dataset.',
    icon: ServerIcon,
  },
]

const api_call_code = `
import requests
url = "" # Replace with actual API URL

# Define your payload
payload = {
    "firehose": False,  # True or False
    "company": None,  # Replace with actual company name or None
    "subprocessor": "OpenAI"  # Replace with actual subprocessor name or None
}


# Headers including the authorization token
headers = {
    "Authorization": f"Bearer {api_key}",
    "Content-Type": "application/json"
}

# Make the POST request
response = requests.post(url, json=payload, headers=headers)

# Check the response
if response.status_code == 200:
    print("Success:", response.json())
else:
    print("Error:", response.status_code, response.text)
`;

const api_response = `
{"data":[
  {"Company": "Ada", "Subprocessor": "OpenAI", "Company_Sector": "Enterprise Tech", "Company_Ticker_If_Public": null, "Company_Country": "Canada", "Company_City": "Toronto", "Valuation_USD_Billions": 1.2, "Last_Updated": "2023-11-16T21:01:17.304465+00:00"},
  {"Company": "Atlassian Corporation Plc", "Subprocessor": "OpenAI", "Company_Sector": "Technology", "Company_Ticker_If_Public": "TEAM", "Company_Country": "Australia", "Company_City": null, "Valuation_USD_Billions": 45.927412576, "Last_Updated": "2023-11-16T20:00:53.485690+00:00"},
  {"Company": "BloomReach", "Subprocessor": "OpenAI", "Company_Sector": "Consumer & Retail", "Company_Ticker_If_Public": null, "Company_Country": "United States", "Company_City": "Mountain View", "Valuation_USD_Billions": 2.2, "Last_Updated": "2023-11-01T16:59:44.219283+00:00"},
  {"Company": "ClickUp", "Subprocessor": "OpenAI", "Company_Sector": "Enterprise Tech", "Company_Ticker_If_Public": null, "Company_Country": "United States", "Company_City": "San Diego", "Valuation_USD_Billions": 4.0, "Last_Updated": "2023-10-27T01:44:48.579395+00:00"},
  {"Company": "Canva", "Subprocessor": "OpenAI", "Company_Sector": "Enterprise Tech", "Company_Ticker_If_Public": null, "Company_Country": "Australia", "Company_City": "Surry Hills", "Valuation_USD_Billions": 25.4, "Last_Updated": "2023-11-16T20:09:02.539091+00:00"},
  {"Company": "Census", "Subprocessor": "OpenAI", "Company_Sector": "Enterprise Tech", "Company_Ticker_If_Public": null, "Company_Country": "United States", "Company_City": "San Francisco", "Valuation_USD_Billions": 0.63, "Last_Updated": "2023-11-19T15:50:25.991599+00:00"},
`;

const HomePage = () => {

  return (
    <div className="bg-white">
    <Helmet>
        <title>AlphaInquire.com Proprietary Data</title>
        <meta name="description" content="AlphaInquire.com uses AI to collect Proprietary Data" />
    </Helmet>

      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
    <div className="overflow-hidden py-6 sm:py-10">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4" id="how-it-works">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">Alpha Inquire Data</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Leverage our b2b company data to uncover insights</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Company subprocessors provide unique insights into technology market share, trends, and more.
                Alpha Inquire uses AI agents to collect and clean this data from the web.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src="/Top_5_Subprocessors.png"
            alt="Top 5 Subprocessors"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>

    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Pricing</h2>
        <p className="text-center mt-6 text-lg leading-8 text-gray-600">
            Two ways to buy our data: monthly subscription to our Limited API or an annual subscription to our Firehose API.
        </p>
        <stripe-pricing-table pricing-table-id="prctbl_1ODI3wKFxdT22Qzo321ECMuQ"
        publishable-key="pk_live_51NJmx5KFxdT22QzoUTwBpAcJDVz5ATfLHqpkXyEsvm6VhO0ihXtfSIpYXaSyPsimxe8EaESS48folMuJ3l313grm00wxZe9NZw">
        </stripe-pricing-table>
      </div>
    </div>


    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Example API Interaction</h2>
        <p className="text-center mt-6 text-lg leading-8 text-gray-600">
            Here's an example of what you'll get from the Alpha Inquire Subprocessor data. For more info, check out our Docs.
          </p>
          <div className="w-full">
          <Tab.Group>
          <Tab.List className="flex p-1 space-x-1 bg-gray-200" aria-label="Tab List">
            <Tab className={({ selected }) => (
              `w-full py-2.5 text-center rounded-md ${selected ? 'text-white bg-indigo-500' : 'text-gray-800'}`
            )}>
              Making the API Call
            </Tab>
            <Tab className={({ selected }) => (
              `w-full py-2.5 text-center rounded-md ${selected ? 'text-white bg-indigo-500' : 'text-gray-800'}`
            )}>
              Small Sample of API Response
            </Tab>
          </Tab.List>
            <Tab.Panels className="mt-2">
              <Tab.Panel>
                <CopyBlock
                  text={api_call_code}
                  language="python"
                  showLineNumbers="True"
                  wrapLines
                />
              </Tab.Panel>
              <Tab.Panel>
                <CodeBlock
                  text={api_response}
                  language="python"
                  showLineNumbers="True"
                  wrapLongLines="True"
                />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </div>

    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">How Do I Get Started?</h2>
        <p className="text-center mt-6 text-lg leading-8 text-gray-600">
            1. Sign up for a subscription to our Limited API or Firehose API<br />
            2. Create an Alpha Inquire account using the same e-mail address<br />
            3. Get your API key and start downloading data via our API<br />
        </p>
      </div>
    </div>

    <section className="relative isolate overflow-hidden px-6 py-24 sm:py-32 lg:px-8">
      
      <div className="mx-auto max-w-2xl lg:max-w-4xl" id="aboutus">
        <img className="mx-auto h-12" src="https://tailwindui.com/img/logos/workcation-logo-indigo-600.svg" alt="" />
        <figure className="mt-10">
          <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
            <p>
            Alpha Inquire is built by <a href="https://www.asterave.com" target="_blank" rel="noopener noreferrer" className="underline">Aster</a>, a small team that is passionate about generative AI and the future of work.
            </p>
          </blockquote>
        </figure>
      </div>
    </section>

      </div>
    </div>
  );
};

export default HomePage;
