import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';

const BuyData = ({ handleCopyToClipboard }) => {
  return (
    <div className="mx-auto xl:w-2/3 w-full justify-center items-center mt-5">
      <h2 className="text-base font-semibold leading-7 text-gray-900">Getting Started with Subprocessor Data</h2>
      <ol className="list-decimal list-inside mt-1 text-sm leading-6 text-gray-600">
        <li>Subscribe via Stripe (below) to the <b>Limited API</b> (billed Monthly, 25 rows only) or the <b>Firehose API</b> (billed Yearly, full dataset)</li>
        <li>Copy your API Key (also below)</li>
        <li>Study our <a href="/docs" className="underline">Docs</a> and call our API to get data</li>
      </ol>
      <br />

      <div className="border-y border-gray-900/10 pb-8">
        <div className="mt-5">
          <div className="flex justify-between items-center mb-2">
            <h2 className="text-base font-semibold leading-7 text-gray-900">Want to use the API?</h2>
            <button onClick={handleCopyToClipboard} className="bg-blue-500 text-white text-md p-2 rounded">
              <FontAwesomeIcon icon={faClipboard} /> Copy API Key
            </button>
          </div>
          <p className="text-base leading-7 text-gray-900">Check out the <a href="/docs" className="underline">docs</a></p>
        </div>
      </div>
      
      <h2 className="text-base font-semibold leading-7 text-gray-900 py-8">Subscribe Now</h2>
      <stripe-pricing-table pricing-table-id="prctbl_1ODI3wKFxdT22Qzo321ECMuQ"
      publishable-key="pk_live_51NJmx5KFxdT22QzoUTwBpAcJDVz5ATfLHqpkXyEsvm6VhO0ihXtfSIpYXaSyPsimxe8EaESS48folMuJ3l313grm00wxZe9NZw">
      </stripe-pricing-table>
    </div>
  );
};

export default BuyData;