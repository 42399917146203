// PreCuratedAgents.js
export const CuratedAgents = [
    { 
        "interests": [
            "Advancements in Large Language Models (LLMs)",
            "New AI research publications",
            "New Generative AI Applications",
        ],
        "name" : "🤖 AI Developments",
        "sources": [ "search_recent_news", "search_arxiv", "search_hacker_news", "search_google_news" ]
    },
    {
      "name": "💼 Brand Monitoring (Replace 'OpenAI' with your company's name under sources & interests)",
      "interests": [
        "Positive news about OpenAI",
        "Negative news about OpenAI"
      ],
      "sources": [
        "search_recent_news",
        "search_google_news",
        "https://openai.com/blog"
      ]
    },
    {
        "interests": [
            "2024 U.S. Presidential Election candidates",
            "Election polling and voter turnouts",
            "Key political issues and platforms for 2024"
        ],
        "name": "🚨 2024 Election",
        "sources": [ "search_recent_news", "search_google_news", "search_google" ]
    },
    {
        "interests": [
            "stock market trends",
            "tech industry stocks",
            "Upcoming IPO announcements"
        ],
        "name": "💼 Stock Market Pulse Monitor",
        "sources": [ "search_recent_news", "search_google_news", "alpha_vantage_news" ]
    },
    {
      "name": "👩‍💻 @alliekmiller AI reading list",
      "interests": [
        "AGI superforecasting",
        "AI-driven analytics, transformers for structured data",
        "AI planning, reasoning, critical thinking, decision-making",
        "future of hardware / how hardware for AI is changing",
        "Mixture of experts (MoE) Models"
      ],
      "sources": [
        "search_google_news",
        "search_arxiv",
        "search_hacker_news"
      ]
    },
    {
      "name": "🤓 Product Hunt - AI Product Launches",
      "interests": [
        "new AI products"
      ],
      "sources": [
        "https://www.producthunt.com/feed?category=undefined"
      ]
    }
  ];
