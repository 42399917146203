import React from 'react';
import { Link } from 'react-router-dom';
const SubscribeManageAccount = ({ user }) => {

  return (
    <>
      <div className="w-5/6 lg:w-2/3 justify-center items-center mx-auto">
        <h2 className="text-2xl py-2 font-bold text-center">Subscribe to Alpha Inquire</h2>
        <h3 className="text-md text-center">Once you subscribe, your agents will automatically send you daily e-mails around 7:30 AM EST. You can cancel anytime.</h3>
        <stripe-pricing-table pricing-table-id="prctbl_1OTFo7KFxdT22QzoBdn6LTfe"
        publishable-key="pk_live_51NJmx5KFxdT22QzoUTwBpAcJDVz5ATfLHqpkXyEsvm6VhO0ihXtfSIpYXaSyPsimxe8EaESS48folMuJ3l313grm00wxZe9NZw">
        </stripe-pricing-table>

          <p class="mb-4 text-lg leading-8 text-center text-gray-600">💼 <a href="https://billing.stripe.com/p/login/7sI8zw5NL86i0aA5kk" className="underline" target="_blank" rel="noopener noreferrer">Manage my Subscription on Stripe</a></p>
      </div>
    </>
  );
};

export default SubscribeManageAccount;