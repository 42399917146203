// src/Sources.js
// Enum for source values
  export const sourceOptions = [
    { value: 'search_recent_news', label: 'Popular News' },
    { value: 'search_google_news', label: 'Deep Cut News' },
    { value: 'search_google', label: 'Google'},
    { value: 'search_arxiv', label: 'arXiv'},
    { value: 'search_hacker_news', label: 'Hacker News'},
    { value: 'alpha_vantage_news', label: 'Stock News'},
    { value: 'company_fundamentals', label: 'Stock Fundamentals'}
  ];