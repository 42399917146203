import React from 'react';
import { Helmet } from 'react-helmet';
import { CloudArrowUpIcon, LockClosedIcon, ServerIcon, CheckIcon } from '@heroicons/react/20/solid';
import { sourceOptions } from './Sources';
import { CuratedAgents } from './CuratedAgents';
import { Link } from 'react-router-dom';


const includedFeatures = [
  'Daily scheduled research summary',
  'Alpha Inquire e-mails delivered to your inbox',
  'Manage your agent: set data sources and interests',
  'API Access for power users',
]

const features = [
  {
    name: 'Configure the topics and data sources you want each Alpha Inquire agent to research.',
    description:
      'Any topic. A variety of data sources, including Google. If it is on the internet, Alpha Inquire can help.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Runs on a daily schedule so you are always up to date.',
    description: 'Start your morning with Alpha Inquire. You will be the smartest person in the office.',
    icon: LockClosedIcon,
  },
  {
    name: 'Generates an e-mail summary, with links in case you want to go deeper.',
    description: 'Ending a meeting 10 minutes early does not actually give anyone time back. But this does.',
    icon: ServerIcon,
  },
]

const getSourceLabel = (sourceValue) => {
  const source = sourceOptions.find(option => option.value === sourceValue);
  return source ? source.label : sourceValue;
}

const HomePage = () => {

  return (
    <div className="bg-white">
    <Helmet>
      <title>Alpha Inquire Research Agents</title>
        <meta name="description" content="Alpha Inquire agents run on a schedule to automate research for you. Try them out for free today!" />
    </Helmet>

      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
    <div className="overflow-hidden py-6 sm:py-10">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4" id="how-it-works">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">Alpha Inquire Research Agents</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">AI-powered research on the topics you care about</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Tell Alpha Inquire what topics you care about and what sources to research for those topics.
                AI does the reading for you and sends you a daily digest with relevant summaries.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src="/product_screenshot.png"
            alt="Product screenshot"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>

    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Popular Research Agents</h2>
        <p className="text-center mt-6 text-lg leading-8 text-gray-600">
            Here are some popular research agents that you can <Link to="/profile" className="text-indigo-600 hover:text-indigo-500 underline">use today.</Link>
          </p>
          <div className="mx-auto mt-10 grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-10 sm:max-w-xl sm:gap-x-10 lg:mx-0 lg:max-w-none">
  {CuratedAgents.map((agent, index) => (
    <div key={index} className="col-span-1 text-center lg:col-span-1">
      <div className="shadow rounded p-4">
        <h3 className="text-xl font-semibold text-gray-900 text-center py-2">{agent.name}</h3>
        <p className="text-sm text-gray-600 py-1">
          <span className='font-bold'>Interests: </span>
          {agent.interests.join(', ')}
        </p>
        <p className="text-sm text-gray-600">
          <span className='font-bold'>Sources:</span>
          {agent.sources.map(source => (
            <span key={source} className="inline-block bg-gray-300 rounded-md px-2 py-1 text-sm font-semibold text-black my-2 mx-1">
              {getSourceLabel(source)}
            </span>
          ))}
        </p>
      </div>
    </div>
  ))}
</div>
      </div>
    </div>

    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Sources for Research Agents</h2>
        <p className="text-center mt-6 text-lg leading-8 text-gray-600">
            Alpha Inquire agents can learn from any of these sources, plus custom URLs and RSS Feeds.
          </p>
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-2 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {sourceOptions.map((source, index) => (
            <div key={index} className="col-span-2 text-center lg:col-span-1">
              {source.label}
            </div>
          ))}
        </div>
      </div>
    </div>

    <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Research Agent Pricing</h2>
    <p className="text-center mt-6 text-lg leading-8 text-gray-600">
        Build and test your agents for free. Subscribe to get daily e-mails with per agent pricing.
    </p>
    <stripe-pricing-table pricing-table-id="prctbl_1OTFo7KFxdT22QzoBdn6LTfe"
    publishable-key="pk_live_51NJmx5KFxdT22QzoUTwBpAcJDVz5ATfLHqpkXyEsvm6VhO0ihXtfSIpYXaSyPsimxe8EaESS48folMuJ3l313grm00wxZe9NZw">
    </stripe-pricing-table>

    <section className="relative isolate overflow-hidden px-6 py-24 sm:py-32 lg:px-8">
      
      <div className="mx-auto max-w-2xl lg:max-w-4xl" id="aboutus">
        <img className="mx-auto h-12" src="https://tailwindui.com/img/logos/workcation-logo-indigo-600.svg" alt="" />
        <figure className="mt-10">
          <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
            <p>
            Alpha Inquire is built by <a href="https://www.asterave.com" target="_blank" rel="noopener noreferrer" className="underline">Aster</a>, a small team that is passionate about generative AI and the future of work.
            </p>
          </blockquote>
        </figure>
      </div>
    </section>

      </div>
    </div>
  );
};

export default HomePage;
