import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { CodeBlock, CopyBlock } from 'react-code-blocks';
import { Disclosure } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { Companies } from './Companies.js';
import { Subprocessors } from './Subprocessors.js';


const limited_api_call = `
import requests
url = "https://cpdough--data-api-get-data.modal.run"

# Define your payload
payload = {
    "firehose": False, # Set to False for Limited API
    "company": None,  # Replace with actual company name or None
    "subprocessor": "OpenAI"  # Replace with actual subprocessor name or None
}

# Headers including the authorization token
headers = {
    "Authorization": f"Bearer {api_key}",
    "Content-Type": "application/json"
}

# Make the POST request
response = requests.post(url, json=payload, headers=headers)

# Check the response
if response.status_code == 200:
    print("Success:", response.json())
else:
    print("Error:", response.status_code, response.text)
`;

const firehose_api_call = `
import requests
url = "https://cpdough--data-api-get-data.modal.run"

# Define your payload
payload = {
    "firehose": True
}

# Headers including the authorization token
headers = {
    "Authorization": f"Bearer {api_key}",
    "Content-Type": "application/json"
}

# Make the POST request
response = requests.post(url, json=payload, headers=headers)

# Check the response
if response.status_code == 200:
    print("Success:", response.json())
else:
    print("Error:", response.status_code, response.text)
`;

const Docs = () => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

    return (
      isAuthenticated && (
      <div className="bg-white">
        <div className="relative isolate px-6 pt-14 lg:px-8">
          <div className="mx-auto max-w-2xl py-20 lg:py-22">
            <div className="text-center">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                Alpha Inquire API Documentation
              </h1>
              <h2 className="text-2xl mt-4 font-bold text-gray-600">
                Learn how to use our API with Python.
              </h2>
            </div>
            <div className="mt-10">
              <h3 className="text-xl font-bold text-gray-900">
                Table of Contents
              </h3>
              <ul className="list-disc ml-5">
                <li><a href="#introduction" className="text-indigo-600">Introduction</a></li>
                <li><a href="#authentication" className="text-indigo-600">Authentication</a></li>
                <li><a href="#making-requests" className="text-indigo-600">Making Requests</a></li>
                <li><a href="#handling-responses" className="text-indigo-600">Handling Responses</a></li>
              </ul>
            </div>
            <div className="mt-10" id="introduction">
              <h3 className="text-xl font-bold text-gray-900">
                Introduction
              </h3>
              <p className="mt-2 text-gray-600">
                Welcome to the Alpha Inquire API. Use this API to interact with Alpha Inquire Data.
              </p>
            </div>
            <div className="mt-10" id="authentication">
              <h3 className="text-xl font-bold text-gray-900">
                Authentication
              </h3>
              <p className="mt-2 text-gray-600">
                To authenticate your requests, you need to include your API key in the headers. You can find your API key on the <a href="https://www.alphainquire.com/profile">My Account</a> page.
                <pre>
                <code>
{`
headers = {
"Content-Type": "application/json",
"Authorization": "Bearer {api_key}"
}
`}
                </code>
                </pre>
                Your request will be rejected if you do not have a Stripe subscription with the same e-mail address as your Alpha Inquire account.
              </p>
            </div>
            <div className="mt-10" id="making-requests">
              <h3 className="text-xl font-bold text-gray-900">
                Making Requests
              </h3>
              <h4 className="text-lg font-bold text-gray-700">
                Limited API
              </h4>
              <p className="mt-2 text-gray-600">
                Here is an example of how to make a request to the Limited API for companies that use the Subprocessor OpenAI. This example uses the `requests` library in python.
              </p>
              <CopyBlock
                  text={limited_api_call}
                  language="python"
                  showLineNumbers="True"
                />
              <h4 className="text-lg font-bold text-gray-700">
                Firehose API
              </h4>
              <p className="mt-2 text-gray-600">
                Here is an example of how to make a request to the Firehose API to get all companies and all subprocessors. This example uses the `requests` library in python.
              </p>
              <CopyBlock
                  text={firehose_api_call}
                  language="python"
                  showLineNumbers="True"
                />
              <h4 className="text-lg font-bold text-gray-700">
                Format of Company and Subprocessor Names
              </h4>
              <p className="mt-2 text-gray-600">
                For the Limited API, it may be difficult to know how the company or subprocessor name is stored in the underlying data (such as `Amazon Web Services` vs `AWS`). Here are lists of the top companies and subprocessors in the Limited API for your reference.
              </p>
              <Disclosure>
                <Disclosure.Button className="mt-4 text-lg font-bold text-gray-700 flex justify-between items-center py-2 px-4 rounded shadow hover:bg-gray-200 transition-colors duration-200">
                  List of Companies <FontAwesomeIcon icon={faChevronDown} />
                </Disclosure.Button>
                <Disclosure.Panel className="mt-2 text-gray-600">
                  <ul>
                    {Companies.map((company, index) => (
                      <li key={index}>{company}</li>
                    ))}
                  </ul>
                </Disclosure.Panel>
              </Disclosure>

              <Disclosure>
                <Disclosure.Button className="mt-4 text-lg font-bold text-gray-700 flex justify-between items-center py-2 px-4 rounded shadow hover:bg-gray-200 transition-colors duration-200">
                  List of Subprocessors <FontAwesomeIcon icon={faChevronDown} />
                </Disclosure.Button>
                <Disclosure.Panel className="mt-2 text-gray-600">
                  <ul>
                    {Subprocessors.map((company, index) => (
                      <li key={index}>{company}</li>
                    ))}
                  </ul>
                </Disclosure.Panel>
              </Disclosure>
            </div>
            <div className="mt-10" id="handling-responses">
              <h3 className="text-xl font-bold text-gray-900">
                Handling Responses
              </h3>
              <p className="mt-2 text-gray-600">
                The response from the API will be a JSON object. If the request was successful, you can use the `json()` method to parse the response, which will include both a `data` key and a `status` key. If the request was not successful, you will get an HTTP error code and associated `detail` error message.
              </p>
            </div>
          </div>
        </div>
      </div>
    ));
};

export default Docs;