import React from 'react';

const Banner = ({ runs }) => {
  let content;

  if (runs < 0) { // user has a valid subscription because runs is -1
    content = (
      <p className="text-sm text-center leading-6 text-gray-900">
        <strong className="font-semibold">Thanks for being an Alpha Inquire subscriber!</strong>
        <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
          <circle cx="1" cy="1" r="1" />
        </svg>
        Let us know if you need help: <a href="mailto:hello@alphainquire.com" className="text-blue-600">
        hello@alphainquire.com
      </a>

      </p>
    );
  } else if (runs === 0) { // user has no runs
    content = (
      <p className="text-sm text-center leading-6 text-gray-9000">
        <strong className="font-semibold">Create and run your first Agent! You'll get an e-mail with the results.</strong>
        <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
          <circle cx="1" cy="1" r="1" />
        </svg>
        You get 1 run for free and you've used {runs} so far.
      </p>
    );
  } else { // runs > 0
    content = (
      <p className="text-sm text-center leading-6 text-gray-900">
        <strong className="font-semibold">You've used all your free runs!</strong>
        <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
          <circle cx="1" cy="1" r="1" />
        </svg>
        Click Subscribe and signup via Stripe to keep using Alpha Inquire.
      </p>
    );
  }

  return (
    <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-fuchsia-50 bg-opacity-100 px-6 py-2.5 sm:px-3.5">
      <div className="flex flex-wrap items-center mx-auto gap-x-4 gap-y-2">
        {content}
      </div>
    </div>
  );
};

export default Banner;