// Banner.js
import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

const Banner = () => {
  return (
    <div className="relative isolate flex items-center gap-x-6 overflow-hidden px-6 py-2.5 sm:px-3.5">
      <div className="flex flex-wrap items-center mx-auto gap-x-4 gap-y-2">
        <p className="text-sm leading-6 text-gray-900">
          <strong className="font-semibold">Try Alpha Inquire for Free!</strong>
          <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
            <circle cx={1} cy={1} r={1} />
          </svg>
          Make an account to build and test your first AI-powered research agent.
        </p>
        <Link smooth
          to="/profile"
          className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
        >
          Try Alpha Inquire <span aria-hidden="true">&rarr;</span>
        </Link>
      </div>
    </div>
  );
};

export default Banner;