export const Subprocessors = [
    "Amazon Web Services",
    "Google",
    "Microsoft",
    "Salesforce",
    "Zendesk",
    "Snowflake",
    "Twilio",
    "Datadog",
    "Stripe",
    "Google Cloud Platform",
    "Cloudflare",
    "Slack",
    "Intercom",
    "SendGrid",
    "OpenAI",
    "Sentry.io",
    "Segment",
    "Atlassian",
    "Fivetran",
    "Workday",
    "Marketo",
    "Hubspot",
    "Databricks",
    "MongoDB",
    "Zoom",
    "Gainsight",
    "Amplitude",
    "Auth0",
    "Clearbit",
    "Okta",
    "FullStory",
    "Looker",
    "Mailgun",
    "Pendo",
    "Zapier",
    "Mailgun Technologies",
    "Fastly",
    "Mailchimp",
    "Workato",
    "LaunchDarkly",
    "Oracle",
    "Tableau",
    "Pendo.io",
    "Heap",
    "Gong",
    "Openli",
    "Anthropic",
    "Zoom Video Communications",
    "Splunk",
    "NetApp"
];